@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Star-Rating {
	@include flex(center, center);
	padding-right: 2em;
	height: 100%;
	> * {
		float: right;
	}
	.Star {
		width: 2em;
		position: relative;
		.inner {
			padding: 5px;
			color: #fff;
			top: 0;
			left: 0;
			height: 100%;
			text-align: center;
			font-size: 2em;
			line-height: 2em;
			&:after {
				-webkit-font-smoothing: antialiased;
				color: #fff;
				content: '☆';
				content: url(../../assets/images/highscore/empty-star.svg);
			}
		}
		&--filled {
			.inner {
				&:after {
					content: '★';
					content: url(../../assets/images/highscore/full-star.svg);
				}
			}
		}
	}
}
