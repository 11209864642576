@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Quiz {
	height: 100%;
	@include flex('space-between', 'center', 'column');
	flex-grow: 1;
	&--paused {
		.Quiz-answer {
			cursor: not-allowed;
		}
	}

	&-header {
		@include contain(900px);
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		width: 100%;
		padding: 1em;
		text-align: center;
		z-index: 1;
		border-bottom: 6px solid #fff;
		color: #fff;
		position: fixed;
		left: 0;
		top: 3.8em;
	}

	&-body {
		position: relative;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'column');
		@include contain(900px);
		flex-grow: 1;
		width: 100%;
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
		}

		&--left {
			text-align: left;
			padding: 0.25em 1.25em 0.5em 1.25em;
		}
	}

	// &-imageThumb {
	// 	position: relative;
	// 	text-align: center;

	// 	img {
	// 		vertical-align: middle;
	// 		height: 3.5em;
	// 	}
	// 	&-imageThumbZoom {
	// 		display: inline-block;
	// 		vertical-align: middle;
	// 		margin-left: 0.5em;
	// 		width: 2.5em;
	// 		height: 2.5em;
	// 		// background-image: url('../../../assets/images/icon-zoom.svg');
	// 		background-size: contain;
	// 		background-position: center right;
	// 		background-repeat: no-repeat;
	// 	}
	// }

	&-instructions {
		text-align: center;
		font-weight: bold;
	}

	&-image {
		width: 100%;
		height: 15em;
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	&-answers {
		width: 100%;
		@include flex('flex-start', 'center', 'column');
		flex-grow: 1;
	}

	&-image + &-answers {
		padding-top: 0;
	}

	&-answer {
		width: 100%;
		padding: 1em;
		margin: 0.5em;
		background-color: $brown-light;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		p {
			display: inline;
			margin: 0;
		}

		&.selected {
			background-color: $brown-dark;
			color: #fff;
		}
		&.animateCorrect {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.animateWrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.correct {
			color: $text-light;
			background-color: $success;
		}
		&.wrong {
			color: $text-light;
			background-color: $failure;
		}
	}
}
