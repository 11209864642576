@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Pairs {
	flex-grow: 1;
	@include flex('space-between', 'center', 'column');
	&--paused {
		.Pairs-answer {
			cursor: not-allowed;
		}
	}

	&-header {
		@include contain(900px);
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		width: 100%;
		padding: 1em;
		text-align: center;
		z-index: 1;
		border-bottom: 6px solid #fff;
		color: #fff;
		position: fixed;
		left: 0;
		top: 3.8em;
	}

	&-body {
		position: relative;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'row');
		@include contain(900px);
		flex-grow: 1;
		width: 100%;
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
		}
	}

	&-column {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		height: 100%;
		&--a {
			padding-right: 1.25em;
		}
		&--b {
			padding-left: 1.25em;
		}
		@include hide-scrollbar();
	}

	&-card {
		min-height: 5em;
		width: 100%;
		padding: 1em;
		margin: 0.5em 0;
		background-color: $brown-light;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		@include flex('center', 'center');
		p {
			display: inline;
			margin: 0;
			word-wrap: break-word;
			overflow-wrap: break-word;

			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
		}
		&.bgImg {
			@include flex('center', 'flex-start');
		}
		&.selected {
			background-color: $brown-dark;
			color: #fff;
		}
		&.correct {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.wrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
	}
}
