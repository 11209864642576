$border-radius: 0.25em;

/* Colors */
$red: #E1232D;

$beige: #f3f0e0;
$beige-light: #fffefc;
$beige-dark: #e2dfd0;

$orange: #fdac02;
$orange-dark: #f57d3a;

$green-light: #b8d08d;
$green: #6b6c26;
$green-dark: #51541f;

$text-dark: $green-dark;
$text-light: $beige-light;

$gold: #a20f58;
$silver: #f57d3a;
$bronze: #ffc13f;
$pink: #b3678d;

$logo: #c6103b;
$logo-dark: #a5132a;

$blue: #2f4964;
$teal: #42817a;
$purple: #3d235d;

$brown-light: #c6c0a7;
$brown-dark: #888279;

$success: #5ca39c;
$failure: #d67c1c;

$room1: #AABEDC;
$room1-dark: #AABEDC;
$room2: #475674;
$room2-dark: #475674;
$room3: #324632;
$room3-dark: #324632;
$room4: #FD8264;
$room4-dark: #FD8264;
$room5: #6B1B21;
$room5-dark: #6B1B21;
$room6: #0A1E46;
$room6-dark: #0A1E46;