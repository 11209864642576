@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Room {
	@include defaultPage();
	height: auto;
	@media (min-width: 1024px) {
		background-image: url('../../assets/images/landing-page-bg.svg');
		background-size: auto 100%;
		background-position: 28% 0;
		background-repeat: repeat-x;
	}
}

.Room-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5em;
}

.Room-body {
	padding: 2em;
	color: white;
}
