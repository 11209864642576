@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Popup {
	visibility: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-color: $green-light;
	z-index: 20;
	opacity: 0;
	@include transition('all', 0.33s, 'ease-in-out');
	&--show {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		.Popup-body {
			@include translate(0px, 0px);
		}
	}

	&-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 5em;
	}
	@include contain(900px);
	&-body {
		@include transition('all', 0.3s, 'ease-in-out');
		@include translate(0px, -100px);
		margin: 0 auto;
		padding: 1em;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
		@media (min-width: 1024px) {
			border-radius: 0 0 9px 9px;
		}
	}
	&-wrap {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 2em;
	}

	&-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 3.5em;
		height: 3.5em;
		background-image: url('../../assets/images/icon-x.svg');
		background-size: 1.75 auto;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	&-title {
		font-family: 'EB Garamond';
		font-size: 1.5em;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 1em 1em 1em;
	}

	&-text {
		font-size: 1.5625em;
		line-height: 1.2;
		padding-bottom: 2em;

		span.Popup-animal {
			display: inline-block;
			width: 4em;
			height: 4em;
			background-size: contain;
			background-repeat: no-repeat;
			&.novice {
				background-image: url('../../assets/images/highscore/novice.svg');
			}
			&.amateur {
				background-image: url('../../assets/images/highscore/amateur.svg');
			}
			&.apprentice {
				background-image: url('../../assets/images/highscore/apprentice.svg');
			}
			&.expert {
				background-image: url('../../assets/images/highscore/expert.svg');
			}
			&.master {
				background-image: url('../../assets/images/highscore/master.svg');
			}
			&.grand-master {
				background-image: url('../../assets/images/highscore/grand-master.svg');
			}
		}
	}

	&-points {
		font-size: 1.5625em;
		font-weight: 900;
	}

	&-feedback {
		width: 100%;
		// background-color: rgba(#484740, 0.79);
		// background-color: $brown;
		padding: 2em;
		color: white;
		text-align: left;
		p {
			text-align: center;
			font-size: 1.5625em;
			margin-top: 0;
		}
		> div {
			position: relative;
			font-size: 1.125em;
			padding-left: 1em;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0.45em;
				width: 0.5em;
				height: 0.5em;
				border-radius: 0.5em;
				background-color: $beige;
			}
		}
	}

	&-image {
		width: 100%;
		img {
			width: 100%;
		}
	}

	&-buttons {
		text-align: center;
		padding: 0 1em;
		@include flex(space-between, center);
	}
	&-button {
		display: inline-block;
		font-size: 2em;
		font-weight: 700;
		text-transform: uppercase;
		color: $logo;
		font-family: 'ED Garamond';
		padding: 0.25em 1em;
		border-radius: 0.5em;
	}
	&-iconPlay {
		width: 3em;
		height: 2em;
		margin-top: 1em;
	}
	&-iconX {
		width: 40px;
		margin-top: 1em;
		margin-bottom: 2em;
	}
	&-endNav {
		@include flex(space-between, center);
		.Popup-iconIgen,
		.Popup-iconNy {
			width: 8em;
		}
	}
}

/* Streak + newPointsTier */

.Popup.Popup--streak,
.Popup.Popup--newPointsTier {
	background-color: rgba($beige-light, 0.65);
	.Popup-body {
		position: relative;
		background-color: $beige;
		@include flex('center', 'center');
	}
	.Popup-wrap {
		padding-bottom: 3.3em;
		.Popup-title {
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 1.4em;
			padding: 0;
			color: $logo-dark;
			text-align: left;
		}
	}
	.Popup-text {
		font-size: 1rem;
		margin-top: 1em;
		color: #000;
		text-align: left;
		padding: 0;
		.red {
			color: $logo-dark;
			font-weight: bold;
		}
	}
	.Popup-tierbox {
		margin-top: 1rem;
		@include flex('space-around', 'center');
		.Popup-tier {
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 2em;
			font-weight: bold;
			padding: 0;
			color: $logo-dark;
			text-align: left;
			margin: 0.2em 0;
		}
	}
	.Popup-buttons {
		padding: 0;
		margin-top: 0;
		display: block;
		text-align: center;
		.Popup-button {
			display: none;
			padding: 0.5em 0em;
			width: 8em;
		}
	}
	.Popup-points {
		display: inline-block;
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-size: 3em;
		font-weight: bold;
		padding: 0;
		color: $logo-dark;
		text-align: left;
		margin: 0;
		position: absolute;
		top: 2rem;
		right: 3rem;
	}
}

/* Challenge completed */
.Popup.Popup--challengeCompleted {
	background-color: rgba($beige-light, 0.65);
	.Popup-body {
		min-height: 100%;
		@include flex('center', 'center');
		padding: 0;
		background-color: transparent;
		box-shadow: none;
		height: 100%;
	}
	.Popup-buttons {
		height: 100%;
		width: 100%;
		@include flex('center', 'center', 'column');
	}
}

/* Room completed */
.Popup.Popup--roomCompleted {
	background-color: $beige;
	background-image: url('../../assets/images/landing-page-bg.svg');
	background-size: auto 100%;
	background-position: 28% 0;
	background-repeat: repeat-x;
	overflow: auto;
	@include contain(600px);
	.Popup-body {
		position: relative;
		width: 100%;
		height: auto;
		box-shadow: none;
		padding-bottom: 1em;
		.Popup-text {
			margin: 0;
			margin-top: 1em;
		}
	}
	.Popup-innerCard {
		position: relative;
		background-color: #fff;
		height: auto;
		min-height: 8em;
		margin-top: 1em;
		padding: 0.5em;
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		border-radius: 5px;
		padding-bottom: 3.5em;
	}
	.Popup-tierbox {
		@include flex('space-around', 'center');
		.Popup-tier {
			display: inline-block;
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 2em;
			font-weight: bold;
			padding: 0;
			color: $logo-dark;
			text-align: left;
			margin: 0.2em 0;
		}
	}
	.Popup-wrap {
		padding: 0;
	}
	.Popup-title {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-size: 1.4em;
		padding: 0;
		color: $logo-dark;
		text-align: left;
	}
	.Popup-text {
		font-size: 1rem;
		margin-top: 1em;
		color: #000;
		text-align: left;
		padding: 0;
		margin: 0.5em;
		p {
			margin: 0;
			margin-bottom: 1em;
		}
	}
	// .Popup-icon {
	// 	width: 100%;
	// 	height: 7em;
	// 	background-size: 5em auto;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	&--trophy {
	// 		background-image: url('../../assets/images/icon-trophy-gold.svg');
	// 	}
	// }
}

/* Quiz image */
// .Popup.Popup--quizImage {
// 	@include flex('center', 'center');
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 100%;
// 		// background-image: url('../../assets/images/background-cork.jpg');
// 		background-size: cover;
// 		opacity: 0.07;
// 	}
// 	.Popup-body {
// 		width: 80%;
// 		background-color: transparent;
// 		padding: 0;
// 		@include flex('center', 'center');
// 		@include corner-box();
// 	}
// 	.Popup-image {
// 		background-color: $beige-light;
// 		padding: 4em 0;
// 	}
// }

/* Wayfinding popups */
// .Popup.Popup--wayfinding {
// 	background-image: url('../../assets/images/background-light.jpg');
// 	background-size: cover;
// 	background-position: center center;
// 	background-repeat: no-repeat;
// 	padding: 8em 1.5em 2em 1.5em;
// 	.Popup-body {
// 		height: 28.5em;
// 		width: 100%;
// 		@include flex('flex-start', center, 'column');
// 		padding: 0 1.75em;
// 		background-color: transparent;
// 		background-image: url('../../assets/images/box-dark-big.svg');

// 		background-size: 100% auto;
// 		background-position: top center;
// 		background-repeat: no-repeat;
// 	}
// 	.Popup-text {
// 		width: 100%;
// 		color: $text-light;
// 		text-align: center;
// 		font-weight: bold;
// 		font-size: 1em;
// 		line-height: 1.125em;
// 		padding: 2.5em 0em 1.5em 0em;
// 	}
// 	.Popup-image {
// 		width: 15em;
// 		height: 10em;
// 		overflow: hidden;
// 		border: 3px solid #fdac02;
// 		border-radius: 0.625em;
// 		img {
// 			width: 100%;
// 		}
// 	}
// 	.Popup-buttons {
// 		margin-top: 2em;
// 		display: block;
// 	}
// 	.Popup-button {
// 		font-size: 1.8em;
// 		padding: 0.2em 1em;
// 		border-radius: 0.5em;
// 	}
// }

/* Game intro popup */
// .Popup.Popup--intro {
// 	background-color: $green-light;
// 	background-size: 100% auto, cover;
// 	background-position: bottom center, center center;
// 	background-repeat: no-repeat;
// 	.Popup-body {
// 		background-color: transparent;
// 		height: 100%;
// 		padding: 4em 0em;
// 	}
// 	.Popup-buttons {
// 		margin-top: 2em;
// 		display: block;
// 	}
// 	.Popup-text {
// 		width: 100%;
// 		min-height: 16em;
// 		font-size: 1em;
// 		padding: 3em 3.5em;
// 		background-color: transparent;
// 		background-image: url('../../assets/images/box-light-big.svg');
// 		background-size: contain;
// 		background-position: center center;
// 		background-repeat: no-repeat;
// 	}
// }

.Ribbon {
	&-newPointsTier,
	&-streak {
		@include ribbon(#a5132a, #6b1b21);
	}
	&-roomCompleted {
		&.Room1 {
			@include ribbon($room1, $room1-dark);
		}
		&.Room2 {
			@include ribbon($room2, $room2-dark);
		}
		&.Room3 {
			@include ribbon($room3, $room3-dark);
		}
		&.Room4 {
			@include ribbon($room4, $room4-dark);
		}
		&.Room5 {
			@include ribbon($room5, $room5-dark);
		}
		&.Room6 {
			@include ribbon($room6, $room6-dark);
		}
	}
}
