@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	@include defaultPage();
	justify-content: space-between;
	background-image: url('../../assets/images/landing-page-bg.svg');
	background-size: auto 100%;
	background-position: 28% 0;
	background-repeat: repeat-x;
	padding: 2rem 0.5rem 3rem 0.5rem;
	.LandingPage-logo {
		position: absolute;
		z-index: 10;
		bottom: 0.5em;
		right: 1em;
		width: 7.5em;
		height: 2.25em;
		background-color: transparent;
		background-image: url('../../assets/images/logo-cgl-dark.svg');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: bottom center;
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
		}
	}
	&-header {
		@include flex('center');
		padding: 1rem;
		height: 9rem;
	}

	&-ddLogo {
		width: calc(5.7em * 1.6);
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-heading {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		color: $logo-dark;
		margin: 0;
		font-size: 1.8em;
		text-transform: uppercase;
	}
	.LandingPage-description {
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		margin: 1rem 2rem;
		text-align: center;
		color: #000;
		line-height: 1.4;
	}

	.LandingPage-startBtn {
		width: 17.3em;
		height: 2.22em;
		border-radius: 3.125em;
		padding: 0 1.43em;
		margin: 1rem auto;
		border: $red solid 0.2em;
		background-color: $red;
		background-image: url('../../assets/images/icon-next-white.svg');
		background-size: auto 1em;
		background-position: center right 1em;
		background-repeat: no-repeat;
		color: #fff;
		text-decoration: none;
		@include flex('flex-start', 'center');
	}

	.LandingPage-grundlovsdysten {
		@include contain(900px);
		margin-top: 2em;
		p {
			font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
			margin: 1rem 2rem;
			text-align: center;
			color: #000;
			line-height: 1.4;
		}
		a {
			width: 17.3em;
			height: 2.22em;
			border-radius: 3.125em;
			padding: 0 1.43em;
			margin: 1rem auto;
			border: $red solid 0.2em;
			background-image: url('../../assets/images/icon-next.svg');
			background-size: auto 1em;
			background-position: center right 1em;
			background-repeat: no-repeat;
			color: #000;
			text-decoration: none;
			@include flex('flex-start', 'center');
		}
	}

	&-startBtn {
		margin: 1rem auto;
		width: 4.8em;
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-resetCacheBtn {
		position: absolute;
		bottom: 1em;
		top: 1em;
		height: 3rem;
		width: 3rem;
		color: white;
		background-color: $logo;
		padding: 0.125em 0.25em;
		border-radius: 50%;
		@include flex('space-around', 'center');
	}

	// &-teacherBtn {
	// 	font-size: 1.125em;
	// 	position: absolute;
	// 	bottom: 1em;
	// 	width: 100%;
	// 	text-align: center;
	// 	color: $beige;
	// 	font-weight: bold;
	// 	cursor: pointer;
	// }

	&-infoBtn {
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		color: #000;
	}

	.LandingPage-availabilityStatementLink {
		position: absolute;
		left: 5em;
		bottom: 0.5em;
		font-size: 1em;
		text-decoration: underline;
		color: black;
		&:hover,
		&:active,
		&:visited {
			color: black;
		}
	}
}

@media (max-width: 530px) {
	.LandingPage {
		.LandingPage-logo {
			bottom: 2.25em;
		}
	}

}
