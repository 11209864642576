@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.RoomOverview {
	@include defaultPage();
	justify-content: center;
	text-align: center;
	@media (min-width: 1024px) {
		background-image: url('../../assets/images/landing-page-bg.svg');
		background-size: auto 100%;
		background-position: 28% 0;
		background-repeat: repeat-x;
	}
	padding: 6em 0.4em 0 0.4em;
	&-header {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 6rem;
		padding-top: 6em;
	}
	&-ddLogo {
		position: absolute;
		top: 1em;
		left: 1em;
		width: 5.7em;
		height: auto;
		background-size: contain;
		background-position: top right;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	&-chooseLanguage {
		position: absolute;
		top: 1.7em;
		right: 1em;
		width: 3em;
		height: 3em;
		background-size: contain;
		background-position: top right;
		background-repeat: no-repeat;
		&.lang--dk {
			background-image: url('../../assets/images/en-flag.svg');
		}
		&.lang--en {
			background-image: url('../../assets/images/dk-flag.svg');
		}
	}

	&-heading {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		color: $logo-dark;
		text-align: left;
		padding-left: 2rem;
		font-size: 1.2em;
		margin: 0;
	}

	&-description {
		@include contain(900px);
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		margin: 2rem;
		margin-top: 6em;
		text-align: left;
		color: #000;
		line-height: 1.4;
		margin-bottom: 2rem;
	}

	&-rooms {
		@include contain(900px);
		padding: 0em 1em;
	}

	&-Btn {
		color: #fff;
		padding: 0.5em;
		border-radius: 5px;
		margin-bottom: 1em;
		@include box-shadow(0, 2px, 2px, 0, rgba(#000000, 0.56));
		p {
			padding: 0;
			margin: 0.3em;
		}
		&-0 {
			background-color: $room1;
			&:hover,
			&:active {
				background-color: darken($room1, 5%);
			}
		}
		&-1 {
			background-color: $room2;
			&:hover,
			&:active {
				background-color: darken($room2, 5%);
			}
		}
		&-2 {
			background-color: $room3;
			&:hover,
			&:active {
				background-color: darken($room3, 5%);
			}
		}
		&-3 {
			background-color: $room4;
			&:hover,
			&:active {
				background-color: darken($room4, 5%);
			}
		}
		&-4 {
			background-color: $room5;
			&:hover,
			&:active {
				background-color: darken($room5, 5%);
			}
		}
		&-5 {
			background-color: $room6;
			&:hover,
			&:active {
				background-color: darken($room6, 5%);
			}
		}
		span {
			display: block;
			font-weight: bold;
		}
	}
}
