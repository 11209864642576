@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Order {
	height: 100%;
	@include flex('space-between', 'center', 'column');
	flex-grow: 1;
	&--paused {
		.Order-item {
			cursor: not-allowed;
		}
	}

	&-header {
		@include contain(900px);
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		width: 100%;
		padding: 1em;
		text-align: center;
		z-index: 1;
		border-bottom: 6px solid #fff;
		color: #fff;
		position: fixed;
		left: 0;
		top: 3.8em;
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
		}
	}

	&-boxes {
		margin: 1em 0 0.3em 0;
		padding-left: 1.4em;
		@include flex('space-between', 'center');
	}

	&-boxContainer {
		flex: 1;
		@include flex('space-between', 'center');
		&::after {
			content: '>';
			font-family: monospace;
			font-size: 0.8em;
			color: rgba(255, 255, 255, 0.5);
			margin: 0 0.3em;
			height: 100%;
		}
		&:nth-child(1) {
			margin-left: 0;
		}
		&:nth-last-child(1) {
			.Order-boxText {
				width: calc(100% - 1.15em);
			}
			&::after {
				display: none;
			}
		}
		&--selected {
			.Order-boxText {
				background-color: $brown-dark;
			}
		}
		&--placed {
			color: $text-light;
			.Order-boxText {
				background-color: $success;
			}
		}
		&--correct {
			color: $text-light;
			.Order-boxText {
				background-color: $success;
			}
		}
		&--wrong {
			color: $text-light;
			.Order-boxText {
				background-color: $failure;
			}
		}
	}
	&-boxText {
		padding: 0.5em 0;
		width: 100%;
		background: $brown-light;
		color: #fff;
		font-size: 0.8em;
		text-transform: uppercase;
		font-weight: bold;
		border-radius: 3px;
		p {
			display: inline;
			margin: 0;
		}
		@include no-select();
	}

	&-body {
		position: relative;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'column');
		@include contain(900px);
		flex-grow: 1;
		width: 100%;
	}

	&-items {
		width: 100%;
		flex-wrap: wrap;
		@include flex('flex-start', 'center', 'row');
	}

	&-itemWrap {
		width: 50%;
		position: relative;
	}

	&-item {
		width: calc(100% - 1em);
		padding: 1em;
		margin: 0.5rem;
		background-color: $brown-light;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		p {
			display: inline;
			margin: 0;
		}
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		@include transition(padding, 0.1s, linear);
		&--selected {
			background-color: $brown-dark;
			color: #fff;
		}
		&--placed + .Order-itemNumber {
			color: $text-light;
			background-color: $success;
		}

		&--correct {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&--wrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
	}

	&-itemNumber {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		padding: 1em 0.5em;
		margin: 0.5rem;
		border-radius: 5px 0 0 5px;
		@include transition(color, 0.1s, ease);
		color: transparent;
		height: calc(100% - 1em);
		@include flex(center, center);
	}
}
