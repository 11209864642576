@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.BackButton {
	position: absolute;
	top: 0;
	left: 0.6em;
	width: 1em;
	height: 3.8em;
	z-index: 2;
	flex-basis: 1em;
}
