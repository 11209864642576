@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Sort {
	height: 100%;
	@include flex('space-between', 'center', 'column');
	flex-grow: 1;
	&--paused {
		.Sort-item {
			cursor: not-allowed;
		}
	}

	&-header {
		@include contain(900px);
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		width: 100%;
		padding: 1em;
		text-align: center;
		z-index: 1;
		border-bottom: 6px solid #fff;
		color: #fff;
		position: fixed;
		left: 0;
		top: 3.8em;
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
		}
	}

	&-boxes {
		margin: 1em 0 0.3em 0;
		@include flex('space-between', 'center');
		&--3 {
			font-size: 0.9em;
		}
	}

	&-boxContainer {
		background: $beige;
		border-radius: 5px;
		margin: 5px;
		padding: 1em 0.3em;
		&--red {
			background-color: $failure;
		}
		&--green {
			background-color: $success;
		}
		@include box-shadow(0, 2px, 2px, 0, rgba(#000000, 0.56));
		@include flex('center', 'center');
		flex-grow: 1;
	}
	&-boxText {
		color: #000;
		font-size: 0.8em;
		text-transform: uppercase;
		font-weight: bold;

		p {
			display: inline;
			margin: 0;
		}
		@include no-select();
	}

	&-body {
		position: relative;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'column');
		@include contain(900px);
		flex-grow: 1;
		width: 100%;
	}
	&-items {
		width: 100%;
		@include flex('flex-start', 'center', 'column');
		flex-grow: 1;
	}
	&-item {
		width: 100%;
		padding: 1em;
		margin: 0.5em;
		background-color: $brown-light;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		p {
			display: inline;
			margin: 0;
		}
		&.selected {
			background-color: $brown-dark;
			color: #fff;
		}
		img {
			position: absolute;
			left: 0.5em;
			max-width: 5.625em;
			max-height: 80%;
		}

		&.placed {
			display: none;
		}
		&.correct {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.wrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
	}
}
