@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.StatsLogin {
	height: 100%;
	width: 100%;
	background-color:#1C529C;
	@include flex('center', 'center');
	.StatsLogin-container {
		width: 14.6em;
		height: 15em;
		padding: 1em 1.25em;
		border-radius: 0.6em;
		margin: 0 1em;
		text-align: left;
		background-color: rgba(#12356A, 0.8);
		.StatsLogin-title {
			color: white;
			font-weight: 700;
			margin-bottom: 1em;
			padding-left: 0.75em;
		}
		.StatsLogin-form {
			position: relative;
			input {
				display: block;
				width: 100%;
				height: 2em;
				border: none;
				border-radius: 0.5em;
				padding: 0.5em 1em;
				margin: auto;
				margin-bottom: 1em;
				background-color: #9AD4FF;
				color: #12356A;
				font-weight: normal;
				&::placeholder {color: rgba(#12356A, 0.5);}
			}
			.Button {
				width: 100%;
				height: 2em;
				border-radius: 0.5em;
				margin-bottom: 1em;
				padding: 0;
				color: white;
				font-weight: 300;
				background-color: #16C88E;
				text-transform: none;
				@include flex('center', 'center');
				&:hover {
					background-color: darken(#16C88E, 3%);
				}
				&--isLoading {
					background-image: url('../../assets/images/icon-loading.svg');
					background-size: auto 80%;
					background-position: center right;
					background-repeat: no-repeat;
				}
			}
		}
	}
}

.StatsLogin-errorMessage {
	height: 1.25em;
	margin: 0 1em 0.25em 1em;
	font-size: 0.85em;
	color: #F4514E;
	text-align: left;
}