@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stats {
	height: 100%;
	width: 100%;
	background-color:$beige;
	padding: 4em 2em;
	.Stats-logoutBtn {
		position: absolute;
		top: 1em;
		left: 1em;
		background-color: white;
		color: black;
		border-radius: 0.55em;
		padding: 0.25em 0.5em;
		cursor: pointer;
		&:hover {background-color: #efefef;}
	}

	.Stats-title {
		font-size: 2em;
		text-align: center;
	}

	.Stats-period {
		font-size: 1.1em;
		font-weight: bold;
		text-align: center;
		margin: 2em 0;
	}

	.Stats-rooms {
		width: 50em;
		margin: auto;
		.Stats-room,
		.Stats-roomsHeader,
		.Stats-total {
			@include flex('space-between', 'center');
			margin: 0.25em 0;
			.Stats-roomTitle {
				width: 20em;
				text-align: left;
			}
			.Stats-roomStarted,
			.Stats-roomCompleted {
				width: 15em;
			}
		}
		.Stats-roomsHeader {
			font-weight: bold;
		}
		.Stats-total {
			margin-top: 1em;
			font-weight: bold;
		}
	}
}