@-webkit-keyframes rotate {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}
@keyframes rotate {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}

@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
  to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}